import React, {useEffect} from 'react';
import {useAnimation, motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {withPrefix} from 'gatsby';
import classes from './HeroSection.module.scss';
import PropTypes from 'prop-types';

const HeroSection = ({addStyles = {}, background, title, text}) => {
	const sectionAnim = useAnimation();
	const titleAnim = useAnimation();
	const subTitleAnim = useAnimation();

	const [ref, inView] = useInView({
		threshold: .3,
		triggerOnce: true,
		rootMargin: '100px'
	});

	useEffect(() => {
		if (inView) {
			sectionAnim.start('visible');
			titleAnim.start('visible');
			subTitleAnim.start('visible');
		}
	}, [sectionAnim, inView]);

	return (
		<>
			<div ref={ref}>
				<motion.div
					animate={sectionAnim}
					initial="hidden"
					variants={{
						visible: {opacity: 1, x: 0},
						hidden: {opacity: 0, x: '-500px'}
					}}
					transition={{type: 'spring', stiffness: 40}}
					className={classes.wrapper}
					css={{...addStyles, backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)), url(${withPrefix(`${background}`)})`}}
				>

					<div className={classes.content}>
						<motion.div
							animate={titleAnim}
							initial="hidden"
							variants={{
								visible: {opacity: 1, x: 0},
								hidden: {opacity: 0, x: '-500px'}
							}}
							transition={{type: 'spring', stiffness: 40, delay: .3}}
						>
							<h2>{title}</h2>
						</motion.div>

						<motion.div
							animate={subTitleAnim}
							initial="hidden"
							variants={{
								visible: {opacity: 1, x: 0},
								hidden: {opacity: 0, x: '-500px'}
							}}
							transition={{type: 'spring', stiffness: 40, delay: .5}}
						>
							<div dangerouslySetInnerHTML={{__html: text}}/>
						</motion.div>
					</div>

				</motion.div>
			</div>
		</>
	)
};

HeroSection.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	addStyles: PropTypes.object,
	background: PropTypes.string
};

export default HeroSection;
