export default {
	pageTitle: 'Home | Department for International Trade',
	pageDescription: `The pandemic has accelerated underlying business and consumer trends that many sectors are likely to benefit from, including Cybersecurity, FinTech, EdTech, and Smart Cities. We explore how these digital-ready 
sectors have fared during the pandemic, and the opportunities and challenges they face in the future.`,
	heroSectionOne: {
		background: 'images/heromain.png',
		title: 'Trade and investment opportunities post Covid-19',
		text: `<p>In 2020, acceleration of underlying social and economic trends has created opportunities for many businesses 
		to benefit from the disruption.</p>
		<p>Cybersecurity, FinTech, EdTech, and Smart Cities are among these sectors that have 
		the potential to drive growth in the future. We explore how these digital-ready industries have dealt with the 
		disruption, and the opportunities and challenges they face in the future.</p>`
	},
	cardSectionOne: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			by: 'written by',
			title: 'Digitalisation, datafication and the green agenda will continue to shape businesses and industries.',
			content: `<p>The disruption of the trade and investment landscape will have a lasting impact, with some sectors 
			hit particularly hard. Some will take longer to bounce back, and may look quite different once they do so. But 
			in many cases, the future will be shaped by the deepening and acceleration of several existing trends, particularly 
			digitalisation, datafication and the green agenda.</p>`
		},
		additional: {
			img: 'images/Hero_shutterstock_775889494.jpg',
			comment: {
				text: `The preservation of remote working and social-distancing measures in most markets in 2021, along with 
				the continued 5G rollout, is likely to boost global demand for digital equipment and services, to the benefit 
				of competitive manufacturers and service providers.`,
				subtext: '(The Economist Intelligence Unit, 2021)',
			}
		},
		more: {
			label: 'Read more',
			link: '/briefing-paper/'
		}
	},
	cardSectionTwo: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			by: 'written by',
			title: 'Cybersecurity, FinTech, EdTech, and Smart Cities are among the industries that have the potential to drive growth in the future.',
			content: `<p>Digital businesses are among the few winners of the global trade and investment disruption. Lockdowns 
			have encouraged a remarkable acceleration in digitalisation across many sectors. Opportunities have opened up for 
			companies that move quickly to develop new digital products and services, but there are also some serious risks 
			that could undermine the benefits of this transformation.</p>`
		},
		additional: {
			img: 'images/Thumbnail_EIU_Infographic.png',
			comment: {
				text: `Over the next two to three years, the rollout of vaccines protecting from Covid-19 will make stay-at-home 
				policies less necessary as the threat from the coronavirus fades. However, the digital economy will carry on 
				expanding. Some consumer habits are likely to have changed permanently, while companies have spotted 
				opportunities amid the disruption.`,
				subtext: '(The Economist Intelligence Unit, 2021)',
			}
		},
		more: {
			label: 'Read more',
			link: '/infographic/'
		}
	},
	sliderSectionOne: {
		headerImgPath: 'images/innovation-is-great.png',
		headerImgAlt: 'Innovation is great img alt',
		settings: {
			dots: false,
			arrows: false,
			infinite: false,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3.2,
						slidesToScroll: 1,
						draggable: true,
					}
				}, {
					breakpoint: 991,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 1,
					}
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1.2,
						slidesToScroll: 1,
						draggable: true,
						arrows: true
					}
				}
			]
		},
		items: [
			{
				img: 'images/homepage_fintech.jpg',
				title: 'Powering fintech innovation',
				text: 'How fintech groups like Flux are taking their innovations global.',
				more: {
					label: 'Read more',
					link: '/story/fintech/'
				}
			}, {
				img: 'images/home_smart_cities.jpg',
				title: 'The net-zero cities',
				text: 'From smart buildings to electric vehicles, UK companies are spearheading the clean-tech revolution.',
				more: {
					label: 'Read more',
					link: '/story/smart-cities/'
				}
			}, {
				img: 'images/home_edtech.jpg',
				title: 'Inspiring innovative minds with edtech',
				text: 'How Twig Education is reimagining science education on a global scale.',
				more: {
					label: 'Read more',
					link: '/story/edtech/'
				}
			}, {
				img: 'images/home_cybersecurity.jpg',
				title: 'Securing digital identities',
				text: 'A look at Amiqus and other UK startups at the vanguard of cybersecurity.',
				more: {
					label: 'Read more',
					link: '/story/cybersecurity/'
				}
			}
		]
	},
	cardSectionThree: {
		position: 'right',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			by: 'written by',
			title: 'A bright tech future',
			content: `<p>We track the growth of UK tech across four key sectors – cybersecurity, education, finance and 
			smart cities.</p>`
		},
		additional: {
			img: 'images/umbr.jpg'
		},
		more: {
			label: 'Read more',
			link: '/data-story/'
		}
	},
	additionalBottomText:
		`<p>Produced for DIT by (E) BrandConnect, a commercial division of The Economist Group, which operates 
		separately from the editorial staffs of The Economist and The Economist Intelligence Unit. Neither (E) BrandConnect 
		nor its affiliates accept any responsibility or liability for reliance by any party on this content.</p>`
}
