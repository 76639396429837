import React, {Suspense} from "react";
import {Link} from "gatsby";
import Layout from '../components/Layout/Layout';
import Seo from "../components/Seo/Seo";
import {Helmet} from "react-helmet";

/*  Data imports    */
import pageData from '../constants/pages/main';

import ScrollDepth from '@economist/fabric-components/utility/ScrollDepthTracker/ScrollDepth';
import UtilWebVitals from '@economist/fabric-components/utility/utilwebvitals/UtilWebVitals';
import CardSection from '../components/Sections/CardSection/CardSection';
import HeroSection from '../components/Sections/HeroSection/HeroSection';
import SliderSection from '../components/Sections/SliderSection/SliderSection';
import {Page} from '../components/Page';
import {withPrefix} from 'gatsby';
import classes from "../assets/scss/pages/story.module.scss";

const IndexPage = () => {

	const heroSectionOne = pageData.heroSectionOne
		? <HeroSection
			background={pageData.heroSectionOne.background}
			title={pageData.heroSectionOne.title}
			text={pageData.heroSectionOne.text} />
		: null;

	const cardSectionOne = pageData.cardSectionOne
		? <CardSection
			position={pageData.cardSectionOne.position || 'left'}
			data={pageData.cardSectionOne}
		/>
		: null;

	const cardSectionTwo = pageData.cardSectionTwo
		? <CardSection
			position={pageData.cardSectionTwo.position || 'left'}
			data={pageData.cardSectionTwo}
		/>
		: null;

	const sliderSectionOne = pageData.sliderSectionOne
		? <SliderSection
			scale={.2}
			headerImgPath={pageData.sliderSectionOne.headerImgPath || false}
			headerImgAlt={pageData.sliderSectionOne.headerImgAlt || false}
			settings={pageData.sliderSectionOne.settings}
			items={pageData.sliderSectionOne.items}
		/>
		: null; 

	const cardSectionThree = pageData.cardSectionThree
		? <CardSection
			authorImg={'images/innovation-is-great.png'}
			position={pageData.cardSectionThree.position || 'left'}
			data={pageData.cardSectionThree}
		/>
		: null;

	const additionalText = pageData.additionalBottomText
		? <div dangerouslySetInnerHTML={{__html: pageData.additionalBottomText}} className={classes.additionalText}/>
		: null;

	const pageStyles = [{
		cssText: `
			body {
				background-color: var(--gray-E4EFF0);
			}
		`
	}];

	return (
		<>
		<Seo title={pageData.pageTitle} description={pageData.pageDescription} image={"https://futuretradeandinvestment.economist.com/images/HomePage_HeroImage_compressed.jpg"}/>
		<Page>
			<Layout invertMenu={true} menuChild={heroSectionOne}>
				
				<Helmet style={pageStyles}/>
				<UtilWebVitals/>
				<ScrollDepth/>

				<div style={{maxWidth: "1440px", margin: "0 auto"}}>

					{cardSectionOne}

					{cardSectionTwo}

					{sliderSectionOne}

					{cardSectionThree}

					{additionalText}

				</div>
			</Layout>
		</Page>
		</>
	)
};

export default IndexPage
